import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import ServiceDetailsOne from "../containers/service-details/service-details-one";

const ServiceDetails = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>TEJES | Service Details</title>
        <meta
          name="description"
          content="Denver,USA,usa,networking,it colud solutions,network security,it solutions,it consultancy,technology solutions,it inovations,colarado,california,florida,denver it solutions denver bussiness solutions,denver it companies,it companies in denver,denver networking,cloud solutions denver,microsoft solutions denver,microsoft partner denver,tejes networking denver,tejes it solutions denver,tejas cloud solutions denver,colarado networking company,colarado it solutions,colarado cloud solutions,colarado microsoft partner,colarado it services,colarado azure partner,azure,it services colarado,best it services colarado."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Service Details" />
        {/* service details */}
        <ServiceDetailsOne />
      </LayoutOne>
    </Fragment>
  );
};

export default ServiceDetails;
