import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import BlogFeatured from "../containers/blog/blog-featured";
import BlogPostWithSidebar from "../containers/blog/blog-post-with-sidebar";

const Blog = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>TEJES | Blog</title>
        <meta
          name="description"
          content="Denver,USA,usa,networking,it colud solutions,network security,it solutions."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Blogs" />
        {/* blog featured */}
        <BlogFeatured />
        {/* blog post with sidebar */}
        <BlogPostWithSidebar />
      </LayoutOne>
    </Fragment>
  );
};

export default Blog;
