import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import ServiceFour from "../containers/services/service-four";
import ServiceTwo from "../containers/services/service-two";
// import PricingTableOne from "../containers/pricing-tables/pricing-table-one";
import TestimonialOne from "../containers/testimonials/testimonial-one";

const Service = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>TEJES | Service</title>
        <meta
          name="description"
          content="Denver,USA,usa,networking,it colud solutions,network security,it solutions,it consultancy,technology solutions,it inovations,colarado,california,florida,denver it solutions denver bussiness solutions,denver it companies,it companies in denver,denver networking,cloud solutions denver,microsoft solutions denver,microsoft partner denver,tejes networking denver,tejes it solutions denver,tejas cloud solutions denver,colarado networking company,colarado it solutions,colarado cloud solutions,colarado microsoft partner,colarado it services,colarado azure partner,azure,it services colarado,best it services colarado."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Services" />
        {/* service content */}
        <ServiceFour />
        {/* service with text */}
        <ServiceTwo />
        {/* pricing table */}
     {/* <PricingTableOne /> */}
        {/* testimonials */}
        <TestimonialOne />
      </LayoutOne>
    </Fragment>
  );
};

export default Service;
