import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import Breadcrumb from "../components/UI/breadcrumb";
import FeatureImageTextOne from "../containers/feature-image-texts/feature-image-text-one";
import ServiceFive from "../containers/services/service-five";
import AboutContentOne from "../containers/about-contents/about-content-one";
// import AboutContentTwo from "../containers/about-contents/about-content-two";

const About = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>TEJES | About</title>
        <meta
          name="Networking"
          content="we give you the tailored networking options on demeand of clients requirements.
          The aim of our all-inclusive IT services and operations is to minimize your overall IT cost by the provision of technical IT support and also give you a return on your investment based on the following measures: reliability, flexibility, scalability, security, redundancy and performance,denver,networking,california,usa,."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="About Us" />
        {/* feature */}
        <FeatureImageTextOne />
        {/* service five */}
        <ServiceFive />
        {/* about content */}
        <AboutContentOne />
        {/* about content */}
        {/* <AboutContentTwo /> */}
      </LayoutOne>
    </Fragment>
  );
};

export default About;
