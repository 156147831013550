import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts";
import NotFoundContent from "../containers/not-found";
import Breadcrumb from "../components/UI/breadcrumb";

const NotFound = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>TEJES | Not Found</title>
        <meta
          name="description"
          content="Denver,USA,usa,networking,it colud solutions,network security,it solutions,it consultancy,technology solutions,it inovations,colarado,california,florida,denver it solutions denver bussiness solutions,denver it companies,it companies in denver,denver networking,cloud solutions denver,microsoft solutions denver,microsoft partner denver,tejes networking denver,tejes it solutions denver,tejas cloud solutions denver,colarado networking company,colarado it solutions,colarado cloud solutions,colarado microsoft partner."
        />
      </MetaTags>
      <LayoutOne>
        {/* breadcrumb */}
        <Breadcrumb title="Not Found" />
        <NotFoundContent />
      </LayoutOne>
    </Fragment>
  );
};

export default NotFound;
