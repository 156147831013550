import React from "react";
import serviceImage from "../../../assets/images/bg/2.jpg";

const ServiceDetailsOne = ({ sidebarPosition }) => {
  return (
    <div className="page-wrapper ptb--110">
      {/*Service section start*/}
      <div className="service-section">
        <div className="container">
          <div className="row">
            <div
              className={`col-lg-8 col-12 ${
                sidebarPosition && sidebarPosition === "left"
                  ? "order-1 order-lg-2"
                  : ""
              }`}
            >
              <div className="service-details">
                {/* service gallery */}
                <div className="service-gallery">
                  <img src={serviceImage} className="img-fluid" alt="" />
                </div>

                <div className="content pt--30">
                  <div className="row">
                    <div className="col-12">
                      <h2>Cloud Base Solutions</h2>
                      <p>
                      You need to know this; using, upgrading, and maintaining your IT has never gotten easier than with technologies from Tejes services. Our team of IT experts help you to host your technology, applications and software. What that means for you is that you can cut the cost of spending on new infrastructure, commissioning new software as well as giving new employees training; saving you cost and time and allowing you focus on your daily operations.
                      </p>
                      <p>
                      These solutions can be designed to meet the need of your Company. So, if you want to benefit from increased market value, we can provide you the answer to that. Our cloud management experts can also assist you with data and infrastructure migrations, cloud analytics, cloud consulting, as well as Automation and optimization.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 pt--30">
                      <h3>Server Hosting</h3>
                      <p>
                      At Tejes services, we offer tailored dedicated server that would help you minimize costs while providing you with the same room to conveniently focus on expanding and grow your business. One advantage of a dedicated server is that they are fully available to your applications. You can also configure and administrate your dedicated server to suit your needs.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 pt--30">
                      <h3>Network Security Assessment</h3>
                      <p>
                      We offer computer and network security assessment with the objective of providing thorough evaluation of you our clients’ network security vulnerability. These are done by our team of expert and certified engineers; who make use of test procedures.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 pt--30">
                      <h3>Back Up and Disaster Recover</h3>
                      <p>
                      At some point, businesses may find themselves in the middle of major data crisis, and that is not a big deal. Our belief at Tejes services is to take proactive measures to prevent data loss. We run our backup solutions background and it would definitely not interrupt your work.Our solutions can have you rest assured that your data remains secure, and our backup support services have been tested and trusted over the years.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 pt--30">
                      <h3>Technical Support</h3>
                      <p>
                      Experiencing issues like downtime is like being lost deep down in the oceans. And you definitely do not want that for your Networks. But you are rest assured to be free of that, because our expert guide knows exactly the safest and most effective routes, and helps you setup your system for a hitch-free experience while also providing answers to any of your questions when you have crisis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`col-lg-4 col-12 ${
                sidebarPosition && sidebarPosition === "left"
                  ? "order-2 order-lg-1"
                  : ""
              }`}
            >
            </div>
          </div>
        </div>
      </div>
      {/*Service section end*/}
    </div>
  );
};

export default ServiceDetailsOne;
