import React from "react";
import { Link } from "react-router-dom";
import Button from "../../../components/UI/button";
import styles from "./BlogFeatured.module.scss";

const BlogFeatured = () => {
  return (
    <div className="banner__area ptb--120">
      <div className="container">
        <div className="row">
          {/* Start Single Banner  */}
          <div className="col-12 col-lg-6">
            <div className={styles.banner}>
              <div className={styles.thumb}>
                <img
                  src={require("./../../../assets/images/blog/Network security.png").default}
                  alt="tejes network"
                />
              </div>
              <div className={styles.hoverAction}>
                <div className={styles.bannerContent}>
                  <h3>
                    <Link to={process.env.PUBLIC_URL + "/blog-post"}>
                      CYBERSECURITY PRACTICES
                    </Link>
                  </h3>
                  <div className={styles.bannerBtn}>
                    <Button type="link" url="/blog-post" text="Read More" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End  Single Banner  */}
          {/* Start Single Banner  */}
          <div className="col-12 col-lg-6">
            <div className={styles.banner}>
              <div className={styles.thumb}>
                <img
                  src={require("./../../../assets/images/blog/cloud.png").default}
                  alt="tejes network"
                />
              </div>
              <div className={styles.hoverAction}>
                <div className={styles.bannerContent}>
                  <h3>
                    <Link to={process.env.PUBLIC_URL + "/blog-post"}>
                      Cloud Storage
                    </Link>
                  </h3>
                  <div className={styles.bannerBtn}>
                    <Button type="link" url="/blog-post" text="Read More" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End  Single Banner  */}
          {/* Start Single Banner  */}
          <div className="col-12 col-lg-6">
            <div className={styles.banner}>
              <div className={styles.thumb}>
                <img
                  src={require("./../../../assets/images/blog/IT.png").default}
                  alt="tejes network"
                />
              </div>
              <div className={styles.hoverAction}>
                <div className={styles.bannerContent}>
                  <h3>
                    <Link to={process.env.PUBLIC_URL + "/blog-post"}>
                      Standard IT Operations
                    </Link>
                  </h3>
                  <div className={styles.bannerBtn}>
                    <Button type="link" url="/blog-post" text="Read More" />
                  </div>
                </div>
              </div>
            </div>
          </div>
              </div>
              
            </div>
          </div>
          );
};

export default BlogFeatured;
