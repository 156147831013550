import React from "react";
// import { Link } from "react-router-dom";
// import BlogSidebar from "../blog-sidebar";
// import styles from "./BlogPostWithSidebar.module.scss";

const BlogPostWithSidebar = ({ sidebarPosition }) => {
  return (
    <div className="tejes__blog__area pb--120">
      <div className="container">
        <div className="row">
          <div
            className={` col-12 col-lg-8 ${
              sidebarPosition && sidebarPosition === "left"
                ? "order-1 order-lg-2"
                : ""
            }`}
          >
        </div>
        </div>
        </div>
        </div>
  );
};

export default BlogPostWithSidebar;
